import {Component, OnInit} from '@angular/core';
import {AppStateService} from '../../../core/services/app-state.service';
import {KommDataPostResponse} from '../../../core/models/komm-data-post.response';
import {UrlApiService} from '../../../core/services/url-api.service';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';
import {ProgramParticipationType} from '../../../core/models/kommdata.model';
import {AuthService} from '../../../core/services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'dsmkd-kommdata-add-success',
  templateUrl: './komm-data-add-success.component.html',
  styleUrls: ['./komm-data-add-success.component.scss']
})
export class KommDataAddSuccessComponent implements OnInit {

  kommDataAddResponse: KommDataPostResponse;
  programParticipationTypes: ProgramParticipationType[];
  inboundRoute: string;
  isCustomerFromSDW: boolean;
  cameFromEdit: boolean = false;

  constructor(private readonly appStateService: AppStateService,
              private readonly urlApiService: UrlApiService,
              private readonly serviceHubRedirectService: ServiceHubRedirectService,
              private readonly authService: AuthService,
              private readonly route: ActivatedRoute,
              private readonly router: Router
  ) {

  }

  ngOnInit(): void {
    this.kommDataAddResponse = this.appStateService.kommDataPostResponse;
    this.programParticipationTypes = this.appStateService.programParticipationTypes;
    this.inboundRoute = this.urlApiService.getParams()?.inboundRoute;
    this.isCustomerFromSDW = this.authService.isNovaSDW();
    this.route.queryParams.subscribe(params => {
      this.cameFromEdit = params['from'] === 'edit';
    });  
  }

  showRegErrorMessage(): boolean {
    // if registrationResponse is undefined or regCallSuccessful is null we don't show the error message
    return this.kommDataAddResponse.registrationResponse?.registrationCallSuccessful === false;
  }

  navigateToServiceHub() {
    this.serviceHubRedirectService.doRedirect();
  }

  navigateToEdit() {
    this.router.navigate(['/edit']);
  }
}


