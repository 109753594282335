import {Component, OnInit, ViewChild} from '@angular/core';
import {ModelService} from '../../../core/services/model.service';
import {
  AuthorizedRepresentative,
  KommDataBackendModel,
  KommDataItem,
  KommDataModel,
  ProgramParticipationStatus,
  ProgramParticipationType,
  RegistrationResponse
} from '../../../core/models/kommdata.model';
import {KommDataService} from '../../../core/services/komm-data.service';
import {Router} from '@angular/router';
import {ContactDataInputComponent} from '../../../shared/components/contact-data-input/contact-data-input.component';
import {AppStateService} from '../../../core/services/app-state.service';
import {Title} from '@angular/platform-browser';
import {UrlApiService} from '../../../core/services/url-api.service';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';
import {AttestationRedirectService} from '../../../core/services/attestation-redirect.service';
import {ErrorType} from '../../../komm-data-change/model/komm-data-change-response.model';
import {KommDataProgramOverviewComponent} from '../komm-data-programm-overview/komm-data-program-overview.component';
import {ProgramUtilService} from '../../util/program-util.service';
import {ErrorMessage, Overlay} from '../../../core/models/resilience.model';
import {ScrollingService} from '../../../core/services/scrolling.service';
import {AuthService} from '../../../core/services/auth/auth.service';


@Component({
  selector: 'dsmkd-kommdata-digital-profile',
  templateUrl: './komm-data-digital-profile.component.html',
  styleUrls: ['./komm-data-digital-profile.component.scss'],
})
export class KommDataDigitalProfileComponent implements OnInit {

  @ViewChild('emailField') emailField: ContactDataInputComponent;

  @ViewChild('mobileNumberField') mobileNumberField: ContactDataInputComponent;

  @ViewChild('kommDataProgrammOverviewComponent')
    programComponent: KommDataProgramOverviewComponent;

  readonly KOMM_DATA_ADD_TITLE = 'Kommunikationsdaten hinzufügen';
  readonly PROGRAM_PARTICIPATIONS = 'program-participations';

  readonly DSM_ATTESTATION = 'DSM_ATTESTATION';
  readonly DIGITAL_SERVICE_HUB = 'DSH';

  emails: KommDataItem[];
  mobileNumbers: KommDataItem[];

  mobileNumberFieldType = 'phone';
  emailFieldType = 'email';
  inboundRoute: string;
  showErrorMissingKommData = false;

  isSubmitDisabled = true;
  isDaiVisible = true;
  kundenName: string = '';
  trackingSourceDetail = '';
  showEmailAlreadyExistsError: boolean;
  legalPerson = false;
  authorizedRepresentative: AuthorizedRepresentative = {
    firstname: '',
    lastname: '',
    role: ''
  };
  hasWewe: boolean = false;
  hasMaz: boolean = false;
  hasAddress: boolean = false;
  hasDewe: boolean = false;
  isNovaSDWUser: boolean = false;

  constructor(private readonly modelService: ModelService,
              private readonly kommDataService: KommDataService,
              private readonly router: Router,
              private readonly appStateService: AppStateService,
              private readonly titleService: Title,
              private readonly serviceHubRedirectService: ServiceHubRedirectService,
              private readonly attestationRedirectService: AttestationRedirectService,
              private readonly urlApiService: UrlApiService,
              private readonly programUtilService: ProgramUtilService,
              private readonly scrollingService: ScrollingService,
              private readonly authService: AuthService) {

    const kommData = this.modelService.getKommData();
    this.emails = kommData.emails.length === 0 ? null : kommData.emails;
    this.mobileNumbers = kommData.mobileNumbers.length === 0 ? null : kommData.mobileNumbers;
    this.titleService.setTitle(this.KOMM_DATA_ADD_TITLE);
    this.inboundRoute = this.urlApiService.getParams()?.inboundRoute;
    this.trackingSourceDetail = this.urlApiService.getParams()?.trackingSourceDetail;
    this.hasMaz = this.programUtilService.hasMaz(kommData);
    this.hasWewe = this.programUtilService.hasWEWE(kommData);
    this.hasDewe = this.programUtilService.hasDEWE(kommData);
    this.isNovaSDWUser = this.authService.isNovaSDW();
    this.checkResilience(kommData);
  }

  ngOnInit(): void {
    this.modelService.getHeaderUserDetails().subscribe(res => {
      if (res.legalEntity) {
        this.legalPerson = true;
      }
      this.kundenName = res.firstName + ' ' + res.lastName;
    },
    () => {
      this.router.navigate(['error', {id: 99999}]);
    });
    this.checkIfUserHasAddress();
  }

  private checkResilience(kommData: KommDataModel): void {
    this.routeToResilienceErrorPage(kommData);
  }

  private routeToResilienceErrorPage(kommData: KommDataModel): void {
    if (this.retrieveMAZStatus(kommData) === ProgramParticipationStatus.YELLOW) {
      this.router.navigate(['add', 'resilience'], {
        state: {
          overlay: Overlay.DIGITAL_PROFILE,
          errorMessage: ErrorMessage.OCS_RUNNING
        }
      });
    }
  }

  private retrieveMAZStatus(kommData: KommDataModel) {
    return kommData.participations.find(participation => participation.type === ProgramParticipationType.MAZ)?.status;
  }

  onSubmit(): void {
    if (this.shouldRouteToSuccessPageWithoutRequest()) {
      this.router.navigate(['add', 'success']);
      return;
    }

    this.emailField?.markAsTouched();
    this.mobileNumberField?.markAsTouched();
    if (this.isMAZChecked()) {
      if (!this.areAllKommDataItemsPresent()) {
        this.programComponent.showErrorMissingKommData = true;
        return;
      }
    }

    if (this.legalPerson && this.isMAZChecked() && !this.areLegalPersonFieldsValid()) {
      return;
    }
    const kommDataToAddModel: KommDataBackendModel = {
      email: null,
      mobileNumber: null,
      registrationItem: null,
      trackingSource: this.inboundRoute ? this.inboundRoute : this.DIGITAL_SERVICE_HUB,
      trackingSourceDetail: this.trackingSourceDetail,
      identified: this.programComponent.checkedPersonalIdentification,
    };

    const fields = [this.emailField, this.mobileNumberField];
    if (this.isAtLeastOneFieldEnabled(fields) && this.areAllEnabledFieldsValid(fields) && !this.isRegInvalid()) {
      kommDataToAddModel.email = this.emailField?.getKommDataInputFieldItem();
      kommDataToAddModel.mobileNumber = this.mobileNumberField?.getKommDataInputFieldItem();

      if (this.isMAZChecked()) {
        kommDataToAddModel.registrationItem = {
          email: this.emailField?.getKommDataItemValue(),
          mobileNumber: this.mobileNumberField?.getKommDataItemValue()
        } as RegistrationResponse;
        if (this.legalPerson) {
          kommDataToAddModel.registrationItem.authorizedRepresentativeRequest = this.authorizedRepresentative;
        }
      }
      this.sendKommDataRequest(kommDataToAddModel);
    }
  }

  isMAZChecked() {
    const naturalPersonWithValidAddress = !this.legalPerson && this.hasAddress;
    return (this.programComponent.checkedBothPrograms && (naturalPersonWithValidAddress || this.legalPerson)) || this.programComponent.checkedMaz;
  }

  private areLegalPersonFieldsValid() {
    return this.authorizedRepresentative.firstname &&
      this.authorizedRepresentative.lastname && this.authorizedRepresentative.role &&
      this.programComponent.checkedPersonalIdentification;
  }

  private isAtLeastOneFieldEnabled(fields: ContactDataInputComponent[]): boolean {
    return fields.some(field => KommDataDigitalProfileComponent.isFieldEnabled(field));
  }

  private areAllEnabledFieldsValid(fields: ContactDataInputComponent[]): boolean {
    return fields.filter(field => KommDataDigitalProfileComponent.isFieldEnabled(field)).every(field => !KommDataDigitalProfileComponent.isFieldInvalid(field));
  }

  private areAllFieldsEnabled(fields: ContactDataInputComponent[]): boolean {
    return fields.every(field => KommDataDigitalProfileComponent.isFieldEnabled(field));
  }

  private static isFieldEnabled(field: ContactDataInputComponent): boolean {
    return field && !field.getNotSpecified();
  }

  private static isFieldInvalid(field: ContactDataInputComponent): boolean {
    return field && !field.valid();
  }

  isRegInvalid(): boolean {
    return !!this.isMAZChecked() && !this.areAllKommDataItemsPresent();
  }

  areAllKommDataItemsPresent(): boolean {
    const fields = [this.emailField, this.mobileNumberField];
    return this.areAllFieldsEnabled(fields) && this.areAllEnabledFieldsValid(fields);
  }

  sendKommDataRequest(kommDataBackendModel: KommDataBackendModel): void {
    this.kommDataService.postKommData(kommDataBackendModel).subscribe(
      res => {
        const body = res.body?.registrationResponse;
        if (body && body.errorType && ErrorType[body.errorType as unknown as keyof ErrorType] === ErrorType.MAZ_ALREADY_EXISTS) {
          this.showEmailAlreadyExistsError = true;
          this.scrollingService.scrollToTop();
        } else {
          this.appStateService.kommDataPostResponse.registrationResponse = body?.registrationResponse;
          this.appStateService.kommDataPostResponse.kommdataAdded = this.emailField?.getKommDataInputFieldItem() != null
            || this.mobileNumberField?.getKommDataInputFieldItem() != null;
          if (this.isMAZChecked() && body?.registrationCallSuccessful) {
            this.appStateService.programParticipationTypes.push(ProgramParticipationType.MAZ);
          }
          if(body?.registrationCallSuccessful === false){
            this.router.navigate(['error', {id: 99999}]);
          } else {
            this.router.navigate(['add', 'success'],{ queryParamsHandling: 'merge' });
          }
        }
      },
      error => {
        this.showEmailAlreadyExistsError = false;
        this.router.navigate(['error', {id: 99999}]);
      });
  }

  // The submit button should be disabled, if both 'notSpecified'-checkboxes are checked
  // or old emails and mobile number are selected and dai is not checked
  optOutStatusChanged(): void {
    this.isSubmitDisabled = (this.emailField.getNotSpecified() && this.mobileNumberField.getNotSpecified());
    this.programComponent.showErrorMissingKommData = false;
    this.showEmailAlreadyExistsError = false;
  }

  private shouldRouteToSuccessPageWithoutRequest(): boolean {
    return (this.emailField.model.selectedValue != null || this.emailField.getNotSpecified())
      && (this.mobileNumberField.model.selectedValue != null || this.mobileNumberField.getNotSpecified()) && !this.isMAZChecked();
  }

  formulateTeaserText(): string {
    if (this.legalPerson) {
      return 'Die vertretungsberechtigte Person des Unternehmens ist ausdrücklich damit einverstanden, ' +
        'dass die Allianz Kunde und Markt GmbH, dienstleistend für die Allianz Private Krankenversicherung-AG, ' +
        'Allianz Versicherungs-AG und Allianz Lebensversicherungs-AG, einmalig eine unverbindliche E-Mail zusendet, ' +
        'in der sowohl MeineAllianz (mAZ) als auch die werbliche Einwilligung (wEWE) angeboten werden.';
    }
    return 'Der Kunde ist ausdrücklich damit einverstanden, dass die Allianz Kunde und Markt GmbH, dienstleistend ' +
      'für die Allianz Private Krankenversicherung-AG, Allianz Versicherungs-AG und Allianz Lebensversicherungs-AG, ' +
      'einmalig eine unverbindliche E-Mail zusendet, in der sowohl Meine Allianz (mAZ) als auch die werbliche ' +
      'Einwilligung (wEWE) angeboten werden.';
  }

  formulateInviteToMAZ(): string {
    let inviteText = 'Einladung zu „Meine Allianz” ';
    if (!this.legalPerson) {
      inviteText += 'und Kommunikation per E-Mail ';
    }
    inviteText += 'versenden';
    return inviteText;
  }


  navigateBack(): void {
    if (this.inboundRoute === this.DSM_ATTESTATION) {
      this.attestationRedirectService.doRedirect();
    } else {
      this.serviceHubRedirectService.doRedirect();
    }
  }

  private checkIfUserHasAddress(): void {
    this.kommDataService.getAddress().subscribe(response => {
      this.hasAddress = !!response.body?.city
        && !!response.body?.street
        && !!response.body?.streetNumber
        && !!response.body?.postalCode;
      this.modelService.setAddress(response.body);
    });
  }
}
